import React from 'react'
import { LayoutWrapper } from '../components/layout/layoutWrapper'
import { BasicKnowledgeGroups } from '../components/knowledge/basicKnowledgeGroups'
import { useNavigationItems } from '../hooks/useNavigationItems'
import { AppLocale, GenerateLocales } from '@hrk/types'
import { SiteMetadata, useSiteMetadata } from '../hooks/useSiteConfig'
import { Seo } from '../components/seo/Seo'

const Wissensbereiche: React.FC = () => {
  const currentSlug = '/en/knowledge-areas'
  const currentLocale: AppLocale = GenerateLocales.de
  const seoTitle = 'Knowledge Areas'
  const seoDescription = 'Knowledge areas on hoch und weit'
  const { collectBreadCrumbs } = useNavigationItems()
  const currentPagePath = collectBreadCrumbs({ slug: currentSlug, title: 'Knowledge areas', type: 'KnowledgeAreas' })

  return (
    <>
      <Seo title={seoTitle} slug={currentSlug} locale={currentLocale} description={seoDescription}></Seo>
      <LayoutWrapper
        currentLocale={currentLocale}
        currentTitle="Knowledge areas"
        currentSlug={currentSlug}
        currentPath={currentPagePath}
        otherLocales={[{ locale: GenerateLocales.de, slug: '/wissensbereiche/' }]}
      >
        <BasicKnowledgeGroups />
      </LayoutWrapper>
    </>
  )
}

export const Head = ({ location, params, data, pageContext }) => {
  const site: SiteMetadata = useSiteMetadata(pageContext.locale as AppLocale)
  return <link rel="canonical" href={`${site.metadata.siteUrl}/en/knowledge-areas/`} />
}

export default Wissensbereiche
